.info {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 7vw;
    row-gap: 11.4vw;
    padding: 13vw var(--m-horizontal) var(--p-76) var(--m-horizontal);

    @include respond(small) {
        grid-template-columns: 1fr;
        padding: 0;
        gap: 0;
    }

    &__upper {
        position: absolute;
        right: 0;
        top: -7vw;
        z-index: 5;
        width: 59.7vw;
        padding: var(--p-60) var(--p-60) var(--p-60) 13vw;
        background-color: var(--primary);
        color: var(--text-secondary);

        @include respond(small) {
            position: static;
            width: 100%;
            padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
        }
    }

    &__image {
        position: absolute;

        @include respond(small) {
            position: static;
            width: 100%;
        }

        &--1 {
            left: 7.29vw;
            top: -2vw;
            z-index: -1;

            @include respond(small) {
                margin-bottom: 2rem;
            }

            img {
                width: 26vw;
                height: auto;
                transform-origin: top center;
                animation: sway 2s ease-in-out 0s infinite alternate-reverse both;

                @include respond(small) {
                    width: 80%;
                }
            }
        }

        &--2 {
            right: 9.1vw;
            bottom: -10vw;
            z-index: 0;

            img {
                width: 40vw;
                height: auto;

                @include respond(small) {
                    width: 90%;
                }
            }
        }
    }

    &__text {
        position: relative;
        z-index: 3;

        @include respond(small) {
            padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);

        }

        &>.highlight {
            margin-top: var(--p-60);
        }
    }
}