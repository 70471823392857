@mixin respond($breakpoint) {
    @if $breakpoint==extra-small {
        @media only screen and (max-width: 36em) {
            @content;
        }

        //576
    }

    @if $breakpoint==small {
        @media only screen and (max-width: 48em) {
            @content;
        }

        //768
    }

    @if $breakpoint==medium {
        @media only screen and (max-width: 62em) {
            @content;
        }

        //992
    }

    @if $breakpoint==large {
        @media only screen and (max-width: 75em) {
            @content;
        }

        //1200
    }

    @if $breakpoint==large-plus {
        @media only screen and (max-width: 86em) {
            @content;
        }

        //1368
    }

    @if $breakpoint==desktop {
        @media only screen and (max-width: 96em) {
            @content;
        }

        //1536
    }

    @if $breakpoint==extra-large {
        @media only screen and (min-width: 120em) {
            @content;
        }

        //1920
    }
}