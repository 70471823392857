*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: var(--font-size);
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1.8rem;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
}


a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: 0px 0px 0px 10px var(--secondary);
}

.grecaptcha-badge {
  @include respond(small) {
    z-index: 20;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/*
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
  box-shadow: none;
}

*/