.header {
    min-height: 100vh;
    position: relative;
    background-color: var(--primary);
    padding: 0 var(--m-horizontal);

    @include respond(small) {
        min-height: auto;
        padding: 0;

        &:before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 7rem;
            width: calc(100% - 3rem);
            background-color: var(--tertiary);
        }
    }

    &__logo {
        position: absolute;
        left: var(--m-horizontal);
        top: 5.3rem;
        z-index: 10;

        svg {
            width: 28.5vw;
            height: auto;

            @include respond(small) {
                width: 20rem;
            }
        }
    }

    &__hero {

        @include respond(small) {
            display: none;
        }

        &--1 {
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 48vw;
            max-height: 82.5vh;
            bottom: 1vw;
            left: 15vw;
            z-index: 3;
        }

        &--2 {
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 40vw;
            max-height: 92vh;
            bottom: 0;
            right: var(--m-horizontal);
        }

        &--1,
        &--2 {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: bottom right;
            }
        }

        &--mobile {
            display: none;
            padding-top: 25rem;

            @include respond(small) {
                display: block;
            }

            img {
                width: 98vw;
                height: auto;
                position: relative;
                z-index: 3;
            }
        }
    }

    &.subpages {
        position: relative;
        background-color: var(--bg-primary);
        padding: 0 var(--m-horizontal);
        min-height: auto;

        &:before {
            content: none;
        }

        .header__logo {
            position: relative;
            margin-top: 5.3rem;
            z-index: 10;
            left: auto;
            top: auto;

            svg {
                width: 15vw;
                height: auto;

                @include respond(small) {
                    width: 20rem;
                }
            }

            h1 {
                color: var(--primary);
            }
        }
    }
}