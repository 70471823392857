:root {
    //Colors 
    --primary: #002a42;
    --secondary: #faf08f;
    --tertiary: #ea5172;
    --quaternary: #7ac8ce;
    --text-primary: #000;
    --text-secondary: #fff;
    --bg-primary: #fff;
    --bg-secondary: #e8ebed;
    --error: #ea5172;
    --disabled: #E0E1E2;

    //Sizes
    --font-size: 62.5%;
    --font-size-72: max(4.8rem, 4.6vw);
    --font-size-63: max(4.4rem, 3.9vw);
    --font-size-40: max(3.8rem, 2.6vw);
    --font-size-32: max(3rem, 2.08vw);
    --font-size-30: max(2.8rem, 1.95vw);
    --font-size-18: max(1.8rem, 1.17vw);
    --font-size-12: max(1.2rem, 0.78vw);

    //Padding
    --p-12: max(1.2rem, 0.78vw);
    --p-18: max(1.8rem, 1.17vw);
    --p-24: max(2.4rem, 1.56vw);
    --p-32: max(3.2rem, 2.08vw);
    --p-48: max(4.3rem, 3.12vw);
    --p-60: max(6rem, 3.9vw);
    --p-76: max(7.6rem, 4.95vw);

    //Margin
    --m-horizontal: max(3rem, 5.20vw);
}


//Fonts
$family-primary: 'Poppins',
    sans-serif;
$font-medium: 400;
$font-bold: 700;
$font-extra-bold: 800;