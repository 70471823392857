.form {
    background-color: var(--bg-secondary);
    padding: max(12rem, 7.29vw) var(--m-horizontal);

    @include respond(small) {
        margin-top: -4rem;
        position: relative;
        z-index: 2;
        padding: var(--p-48) var(--m-horizontal);
    }

    &__container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--p-60);

        @include respond(small) {
            grid-template-columns: 1fr;
            gap: var(--p-24);
        }
    }

    &__primary {
        display: flex;
        flex-direction: column;

        textarea,
        input {
            width: 100%;
            z-index: 8;
            border: 0;
            background-color: var(--bg-primary);
            margin-bottom: var(--p-24);
            padding: var(--p-12);
            font-family: $family-primary;
            font-size: var(--font-size-18);
            line-height: calc(var(--font-size-18) * 1.5);
            color: var(--tertiary);
            font-weight: $font-bold;

            &::placeholder {
                font-family: $family-primary;
                font-size: var(--font-size-18);
                line-height: calc(var(--font-size-18) * 1.5);
                color: var(--tertiary);
                font-weight: $font-bold;
            }
        }

        textarea {
            height: 100%;

            @include respond(small) {
                height: 60vh;
            }
        }
    }

    &__pot {
        height: 0;
        padding: 0;
        visibility: hidden;
        overflow: hidden;
    }

    &__sr-only {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
    }

    &__legal {
        display: grid;
        grid-template-columns: 4rem auto;
        gap: 0.5em;
        margin-bottom: 2rem;

        input[type="checkbox"] {
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            /* For iOS < 15 */
            background-color: var(--form-background);
            /* Not removed via appearance */
            margin: 0;

            font: inherit;
            color: var(--tertiary);
            width: 3.1rem;
            height: 3.1rem;
            border: 3px solid var(--tertiary);
            border-radius: 50%;
            transform: translateY(-0.075em);
            position: relative;

            display: grid;
            place-content: center;
        }

        input[type="checkbox"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--text-secondary);
            /* Windows High Contrast Mode */
            background-color: CanvasText;
        }

        input[type="checkbox"]:checked {
            background-color: var(--tertiary);
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        input[type="checkbox"]:focus {
            outline: max(2px, 0.15em) solid var(--secondary);
            outline-offset: max(2px, 0.15em);
        }

        input[type="checkbox"]:disabled {
            --form-control-color: var(--disabled);

            color: var(--disabled);
            cursor: not-allowed;
        }
    }

    fieldset {
        padding: 0;
        border: 0;
        margin: 0;
    }

    &__submit {
        border: 0;
        background-color: var(--tertiary);
        border-radius: 25px;
        font-family: $family-primary;
        font-weight: $font-bold;
        font-size: var(--font-size-30);
        line-height: calc(var(--font-size-30) * 1.2);
        text-decoration: none;
        text-transform: uppercase;
        color: var(--text-secondary);
        padding: var(--p-18) var(--p-48);
        cursor: pointer;

        &:hover {
            background-color: var(--primary);
        }
    }
}