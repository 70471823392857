html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@keyframes sway {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1deg);
  }
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("poppins-v20-latin-ext_latin-regular.19adfd23.woff2") format("woff2"), url("poppins-v20-latin-ext_latin-regular.e57fddee.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("poppins-v20-latin-ext_latin-700.719e9ed9.woff2") format("woff2"), url("poppins-v20-latin-ext_latin-700.6ed34e78.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("poppins-v20-latin-ext_latin-800.04a01c00.woff2") format("woff2"), url("poppins-v20-latin-ext_latin-800.2d3609c4.woff") format("woff");
}

:root {
  --primary: #002a42;
  --secondary: #faf08f;
  --tertiary: #ea5172;
  --quaternary: #7ac8ce;
  --text-primary: #000;
  --text-secondary: #fff;
  --bg-primary: #fff;
  --bg-secondary: #e8ebed;
  --error: #ea5172;
  --disabled: #e0e1e2;
  --font-size: 62.5%;
  --font-size-72: max(4.8rem, 4.6vw);
  --font-size-63: max(4.4rem, 3.9vw);
  --font-size-40: max(3.8rem, 2.6vw);
  --font-size-32: max(3rem, 2.08vw);
  --font-size-30: max(2.8rem, 1.95vw);
  --font-size-18: max(1.8rem, 1.17vw);
  --font-size-12: max(1.2rem, .78vw);
  --p-12: max(1.2rem, .78vw);
  --p-18: max(1.8rem, 1.17vw);
  --p-24: max(2.4rem, 1.56vw);
  --p-32: max(3.2rem, 2.08vw);
  --p-48: max(4.3rem, 3.12vw);
  --p-60: max(6rem, 3.9vw);
  --p-76: max(7.6rem, 4.95vw);
  --m-horizontal: max(3rem, 5.2vw);
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--font-size);
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 1.8rem;
  position: relative;
  overflow-x: hidden;
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  box-shadow: 0px 0px 0px 10px var(--secondary);
  outline: none;
}

@media only screen and (max-width: 48em) {
  .grecaptcha-badge {
    z-index: 20;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.typo-heading-1, h1 {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-40);
  line-height: calc(var(--font-size-40) * 1.125);
  color: var(--text-secondary);
  text-decoration: none;
}

.typo-heading-2, h2 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: var(--font-size-72);
  line-height: calc(var(--font-size-72) * 1.05);
  color: var(--primary);
  text-decoration: none;
}

.typo-heading-3, h3 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: var(--font-size-63);
  line-height: calc(var(--font-size-63) * 1.05);
  color: var(--primary);
  text-decoration: none;
}

.typo-subtitle {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-30);
  line-height: calc(var(--font-size-30) * 1.2);
  color: var(--primary);
  text-decoration: none;
}

.typo-body, p, ol, ul {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-18);
  line-height: calc(var(--font-size-18) * 1.5);
}

.typo-body:not(:last-of-type), p:not(:last-of-type), ol:not(:last-of-type), ul:not(:last-of-type) {
  margin-bottom: 1em;
}

ol, ul {
  margin-left: var(--p-24);
}

ol li, ul li {
  padding-bottom: 1rem;
  padding-left: 2rem;
}

.typo-legal {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-12);
  line-height: calc(var(--font-size-12) * 1.5);
}

.c-white {
  color: var(--text-secondary);
}

.underline {
  padding-bottom: var(--p-60);
  margin-bottom: var(--p-76);
  position: relative;
}

.underline:before {
  content: "";
  height: max(1.5rem, .98vw);
  width: max(13.8rem, 8.98vw);
  background-color: var(--secondary);
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 48em) {
  .underline {
    margin-bottom: var(--p-48);
  }
}

.highlight {
  color: var(--tertiary);
  font-size: var(--font-size-30);
  line-height: calc(var(--font-size-30) * 1.2);
  font-weight: 700;
}

.header {
  min-height: 100vh;
  background-color: var(--primary);
  padding: 0 var(--m-horizontal);
  position: relative;
}

@media only screen and (max-width: 48em) {
  .header {
    min-height: auto;
    padding: 0;
  }

  .header:before {
    content: "";
    height: 7rem;
    width: calc(100% - 3rem);
    background-color: var(--tertiary);
    position: absolute;
    bottom: -1px;
    left: 0;
  }
}

.header__logo {
  left: var(--m-horizontal);
  z-index: 10;
  position: absolute;
  top: 5.3rem;
}

.header__logo svg {
  width: 28.5vw;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .header__logo svg {
    width: 20rem;
  }
}

@media only screen and (max-width: 48em) {
  .header__hero {
    display: none;
  }
}

.header__hero--1 {
  width: 100%;
  height: 100%;
  max-width: 48vw;
  max-height: 82.5vh;
  z-index: 3;
  position: absolute;
  bottom: 1vw;
  left: 15vw;
}

.header__hero--2 {
  width: 100%;
  height: 100%;
  max-width: 40vw;
  max-height: 92vh;
  bottom: 0;
  right: var(--m-horizontal);
  position: absolute;
}

.header__hero--1 img, .header__hero--2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom right;
}

.header__hero--mobile {
  padding-top: 25rem;
  display: none;
}

@media only screen and (max-width: 48em) {
  .header__hero--mobile {
    display: block;
  }
}

.header__hero--mobile img {
  width: 98vw;
  height: auto;
  z-index: 3;
  position: relative;
}

.header.subpages {
  background-color: var(--bg-primary);
  padding: 0 var(--m-horizontal);
  min-height: auto;
  position: relative;
}

.header.subpages:before {
  content: none;
}

.header.subpages .header__logo {
  z-index: 10;
  margin-top: 5.3rem;
  position: relative;
  top: auto;
  left: auto;
}

.header.subpages .header__logo svg {
  width: 15vw;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .header.subpages .header__logo svg {
    width: 20rem;
  }
}

.header.subpages .header__logo h1 {
  color: var(--primary);
}

.promo {
  grid-template-columns: 48vw 1fr;
  display: grid;
}

@media only screen and (max-width: 48em) {
  .promo {
    grid-template-columns: 1fr;
  }
}

.promo__apla {
  padding: 0 var(--p-60) 3.5vw var(--m-horizontal);
  align-items: flex-end;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .promo__apla {
    padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
  }
}

.promo__apla:before {
  content: "";
  width: 100%;
  height: calc(100% + 10vw);
  background-color: var(--tertiary);
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 48em) {
  .promo__apla:before {
    height: calc(100% + 7rem);
    width: calc(100% - 3rem);
  }
}

.promo__apla h2 {
  color: var(--text-secondary);
  z-index: 3;
  position: relative;
}

.promo__text {
  padding: var(--p-76) var(--m-horizontal) var(--p-60) var(--p-60);
}

@media only screen and (max-width: 48em) {
  .promo__text {
    padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
  }
}

.projects {
  background-color: var(--bg-secondary);
  gap: var(--p-60);
  padding: var(--p-76) 0;
  grid-template-columns: 48vw 1fr;
  display: grid;
}

@media only screen and (max-width: 48em) {
  .projects {
    padding: var(--p-76) var(--m-horizontal);
    grid-template-columns: 1fr;
  }
}

.projects__column:nth-of-type(2n+1) {
  padding: 0 var(--p-60) 0 var(--m-horizontal);
}

@media only screen and (max-width: 48em) {
  .projects__column:nth-of-type(2n+1) {
    padding: 0;
  }
}

.projects__column:nth-of-type(2n) {
  padding: 0 var(--m-horizontal) 0 7.81vw;
}

@media only screen and (max-width: 48em) {
  .projects__column:nth-of-type(2n) {
    padding: 0;
  }
}

.projects__title h3 {
  margin: 0;
}

.projects__intro {
  align-items: flex-end;
  display: flex;
}

.projects__intro p {
  color: var(--tertiary);
  font-weight: 700;
}

.projects__image {
  width: 100%;
  aspect-ratio: 16 / 11;
  isolation: isolate;
  z-index: 8;
  background-color: #fefbdd;
  line-height: 0;
  display: block;
  position: relative;
}

.projects__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  filter: grayscale() contrast(1.2);
  mix-blend-mode: darken;
}

.projects__image:before {
  content: "";
  background-color: var(--primary);
  height: 100%;
  mix-blend-mode: screen;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.projects__image:hover img {
  filter: none;
  mix-blend-mode: normal;
}

.projects__image:hover:before {
  content: none;
}

.projects__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 48em) {
  .projects__text {
    padding: 0;
  }
}

.projects__text p > a {
  color: var(--primary);
  font-weight: 700;
  text-decoration: none;
}

.projects__text p > a:hover {
  color: var(--tertiary);
}

.projects__link {
  margin-top: var(--p-60);
  flex-direction: column;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .projects__link {
    flex-direction: column-reverse;
    margin-top: 0;
    margin-bottom: 4rem;
  }
}

.projects__link:before, .projects__link:after {
  content: "";
  height: 3px;
  width: 31.3vw;
  background-color: var(--tertiary);
  z-index: 10;
  transition: width .35s ease-in-out;
  position: absolute;
  top: 0;
  right: 23.4vw;
}

@media only screen and (max-width: 48em) {
  .projects__link:before, .projects__link:after {
    width: 100%;
    inset: auto auto 2.4rem 0;
  }
}

.projects__link:after {
  width: 0;
  background-color: var(--secondary);
}

.projects__link span {
  text-transform: uppercase;
  margin-top: var(--p-48);
  font-weight: 700;
}

@media only screen and (max-width: 48em) {
  .projects__link span {
    margin-bottom: 3rem;
  }
}

.projects__link:hover span {
  color: var(--tertiary);
}

.projects__link:hover:after {
  width: 31.3vw;
}

.info {
  padding: 13vw var(--m-horizontal) var(--p-76) var(--m-horizontal);
  grid-template-columns: repeat(2, 1fr);
  gap: 11.4vw 7vw;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .info {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0;
  }
}

.info__upper {
  z-index: 5;
  width: 59.7vw;
  padding: var(--p-60) var(--p-60) var(--p-60) 13vw;
  background-color: var(--primary);
  color: var(--text-secondary);
  position: absolute;
  top: -7vw;
  right: 0;
}

@media only screen and (max-width: 48em) {
  .info__upper {
    width: 100%;
    padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
    position: static;
  }
}

.info__image {
  position: absolute;
}

@media only screen and (max-width: 48em) {
  .info__image {
    width: 100%;
    position: static;
  }
}

.info__image--1 {
  z-index: -1;
  top: -2vw;
  left: 7.29vw;
}

@media only screen and (max-width: 48em) {
  .info__image--1 {
    margin-bottom: 2rem;
  }
}

.info__image--1 img {
  width: 26vw;
  height: auto;
  transform-origin: top;
  animation: sway 2s ease-in-out infinite alternate-reverse both;
}

@media only screen and (max-width: 48em) {
  .info__image--1 img {
    width: 80%;
  }
}

.info__image--2 {
  z-index: 0;
  bottom: -10vw;
  right: 9.1vw;
}

.info__image--2 img {
  width: 40vw;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .info__image--2 img {
    width: 90%;
  }
}

.info__text {
  z-index: 3;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .info__text {
    padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
  }
}

.info__text > .highlight {
  margin-top: var(--p-60);
}

.footer {
  grid-template-columns: 72.65vw 1fr;
  display: grid;
}

@media only screen and (max-width: 48em) {
  .footer {
    grid-template-columns: 1fr;
  }
}

.footer__container {
  background-color: var(--primary);
  padding: var(--p-60) var(--m-horizontal);
  grid-template-columns: 33.52vw 1fr;
  margin-top: -3vw;
  display: grid;
}

@media only screen and (max-width: 48em) {
  .footer__container {
    grid-template-columns: 1fr;
  }
}

.footer__logo {
  margin-bottom: var(--p-48);
  grid-column: 1 / 3;
}

@media only screen and (max-width: 48em) {
  .footer__logo {
    grid-column: 1 / 2;
  }
}

.footer__logo svg {
  width: 21vw;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .footer__logo svg {
    width: 60%;
  }
}

.footer__contact {
  padding-left: 8.65vw;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .footer__contact {
    padding: 0;
  }
}

.footer__contact:before {
  content: "";
  bottom: 0;
  left: var(--p-48);
  height: 8.13vw;
  width: 1px;
  background-color: var(--bg-primary);
  position: absolute;
}

@media only screen and (max-width: 48em) {
  .footer__contact:before {
    content: none;
  }
}

.footer__contact a {
  color: var(--text-secondary);
  border-bottom: 1px solid var(--secondary);
  text-decoration: none;
}

.footer__contact a:hover {
  color: var(--secondary);
  border-bottom: 1px solid var(--tertiary);
}

.footer__address a {
  color: var(--text-secondary);
  border-bottom: 1px solid var(--secondary);
  text-decoration: none;
}

.footer__address a:hover {
  color: var(--secondary);
  border-bottom: 1px solid var(--tertiary);
}

.footer__partners {
  padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--p-60);
}

@media only screen and (max-width: 48em) {
  .footer__partners {
    padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
  }
}

.footer__partners img {
  margin-top: var(--p-48);
  width: 11.71vw;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .footer__partners img {
    width: 60%;
  }
}

.text {
  padding: 0 var(--m-horizontal) var(--p-76) var(--m-horizontal);
}

.text__thanks {
  max-width: 60rem;
  padding-top: 20vh;
}

.text__thanks h3, .text__thanks p {
  margin-bottom: var(--p-60);
}

.text__content {
  max-width: 120ch;
  padding: var(--p-60) 0;
}

.text__content h2 {
  font-size: var(--font-size-40);
  margin-bottom: var(--p-24);
  line-height: 1.2;
}

.text__content h3 {
  font-size: var(--font-size-30);
  margin-top: var(--p-24);
}

.text__content a {
  color: var(--primary);
  border-bottom: 1px solid var(--secondary);
  font-weight: 700;
  text-decoration: none;
}

.text__content a:hover {
  color: var(--tertiary);
  border-bottom: 1px solid var(--quaternary);
}

.form {
  background-color: var(--bg-secondary);
  padding: max(12rem, 7.29vw) var(--m-horizontal);
}

@media only screen and (max-width: 48em) {
  .form {
    z-index: 2;
    padding: var(--p-48) var(--m-horizontal);
    margin-top: -4rem;
    position: relative;
  }
}

.form__container {
  width: 100%;
  gap: var(--p-60);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 48em) {
  .form__container {
    gap: var(--p-24);
    grid-template-columns: 1fr;
  }
}

.form__primary {
  flex-direction: column;
  display: flex;
}

.form__primary textarea, .form__primary input {
  width: 100%;
  z-index: 8;
  background-color: var(--bg-primary);
  margin-bottom: var(--p-24);
  padding: var(--p-12);
  font-family: Poppins, sans-serif;
  font-size: var(--font-size-18);
  line-height: calc(var(--font-size-18) * 1.5);
  color: var(--tertiary);
  border: 0;
  font-weight: 700;
}

.form__primary textarea::placeholder, .form__primary input::placeholder {
  font-family: Poppins, sans-serif;
  font-size: var(--font-size-18);
  line-height: calc(var(--font-size-18) * 1.5);
  color: var(--tertiary);
  font-weight: 700;
}

.form__primary textarea {
  height: 100%;
}

@media only screen and (max-width: 48em) {
  .form__primary textarea {
    height: 60vh;
  }
}

.form__pot {
  height: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
}

.form__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.form__legal {
  grid-template-columns: 4rem auto;
  gap: .5em;
  margin-bottom: 2rem;
  display: grid;
}

.form__legal input[type="checkbox"] {
  appearance: none;
  background-color: var(--form-background);
  font: inherit;
  color: var(--tertiary);
  width: 3.1rem;
  height: 3.1rem;
  border: 3px solid var(--tertiary);
  border-radius: 50%;
  place-content: center;
  margin: 0;
  display: grid;
  position: relative;
  transform: translateY(-.075em);
}

.form__legal input[type="checkbox"]:before {
  content: "";
  width: .65em;
  height: .65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform-origin: 0 100%;
  box-shadow: inset 1em 1em var(--text-secondary);
  background-color: CanvasText;
  transition: transform .12s ease-in-out;
  transform: scale(0);
}

.form__legal input[type="checkbox"]:checked {
  background-color: var(--tertiary);
}

.form__legal input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.form__legal input[type="checkbox"]:focus {
  outline: max(2px, .15em) solid var(--secondary);
  outline-offset: max(2px, .15em);
}

.form__legal input[type="checkbox"]:disabled {
  --form-control-color: var(--disabled);
  color: var(--disabled);
  cursor: not-allowed;
}

.form fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.form__submit {
  background-color: var(--tertiary);
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: var(--font-size-30);
  line-height: calc(var(--font-size-30) * 1.2);
  text-transform: uppercase;
  color: var(--text-secondary);
  padding: var(--p-18) var(--p-48);
  cursor: pointer;
  border: 0;
  border-radius: 25px;
  text-decoration: none;
}

.form__submit:hover {
  background-color: var(--primary);
}

/*# sourceMappingURL=index.ce20140e.css.map */
