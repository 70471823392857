.text {
    padding: 0 var(--m-horizontal) var(--p-76) var(--m-horizontal);

    &__thanks {
        padding-top: 20vh;
        max-width: 60rem;

        h3,
        p {
            margin-bottom: var(--p-60);
        }
    }

    &__content {
        max-width: 120ch;
        padding: var(--p-60) 0;

        h2 {
            font-size: var(--font-size-40);
            line-height: 1.2;
            margin-bottom: var(--p-24);
        }

        h3 {
            font-size: var(--font-size-30);
            margin-top: var(--p-24);
        }

        a {
            text-decoration: none;
            color: var(--primary);
            border-bottom: 1px solid var(--secondary);
            font-weight: 700;

            &:hover {
                color: var(--tertiary);
                border-bottom: 1px solid var(--quaternary);
            }
        }
    }
}