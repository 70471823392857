.projects {
    background-color: var(--bg-secondary);
    display: grid;
    grid-template-columns: 48vw 1fr;
    gap: var(--p-60);
    padding: var(--p-76) 0;

    @include respond(small) {
        grid-template-columns: 1fr;
        padding: var(--p-76) var(--m-horizontal);
    }

    &__column {
        &:nth-of-type(odd) {
            padding: 0 var(--p-60) 0 var(--m-horizontal);

            @include respond(small) {
                padding: 0;
            }
        }

        &:nth-of-type(even) {
            padding: 0 var(--m-horizontal) 0 7.81vw;

            @include respond(small) {
                padding: 0;
            }
        }
    }

    &__title {
        h3 {
            margin: 0;
        }
    }

    &__intro {
        display: flex;
        align-items: flex-end;

        p {
            color: var(--tertiary);
            font-weight: 700;
        }
    }

    &__image {
        display: block;
        width: 100%;
        aspect-ratio: 16 / 11;
        background-color: #fefbdd;
        line-height: 0;
        isolation: isolate;
        position: relative;
        z-index: 8;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            filter: grayscale(100%) contrast(1.2);
            mix-blend-mode: darken;
        }

        &:before {
            content: '';
            position: absolute;
            background-color: var(--primary);
            top: 0;
            left: 0;
            height: 100%;
            left: 0;
            mix-blend-mode: screen;
            width: 100%;
            z-index: 1;
        }

        &:hover {
            img {
                filter: none;
                mix-blend-mode: normal;
            }

            &:before {
                content: none;
            }
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond(small) {
            padding: 0;
        }

        p>a {
            font-weight: 700;
            text-decoration: none;
            color: var(--primary);

            &:hover {
                color: var(--tertiary);
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        margin-top: var(--p-60);
        position: relative;
        text-decoration: none;

        @include respond(small) {
            flex-direction: column-reverse;
            margin-bottom: 4rem;
            margin-top: 0;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 23.4vw;
            height: 3px;
            width: 31.3vw;
            background-color: var(--tertiary);
            transition: width 0.35s ease-in-out;
            z-index: 10;

            @include respond(small) {
                top: auto;
                right: auto;
                left: 0;
                bottom: 2.4rem;
                width: 100%;
            }
        }

        &:after {
            width: 0;
            background-color: var(--secondary);
        }


        span {
            text-transform: uppercase;
            font-weight: 700;
            margin-top: var(--p-48);

            @include respond(small) {
                margin-bottom: 3rem;
            }
        }

        &:hover {

            span {
                color: var(--tertiary);
            }

            &:after {
                width: 31.3vw;
            }
        }
    }
}