.typo-heading-1,
h1 {
    font-family: $family-primary;
    font-weight: $font-medium;
    font-size: var(--font-size-40);
    line-height: calc(var(--font-size-40) * 1.125);
    text-decoration: none;
    color: var(--text-secondary);
}

.typo-heading-2,
h2 {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-72);
    line-height: calc(var(--font-size-72) * 1.05);
    text-decoration: none;
    color: var(--primary);
}

.typo-heading-3,
h3 {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-63);
    line-height: calc(var(--font-size-63) * 1.05);
    text-decoration: none;
    color: var(--primary);
}

.typo-subtitle {
    font-family: $family-primary;
    font-weight: $font-medium;
    font-size: var(--font-size-30);
    line-height: calc(var(--font-size-30) * 1.2);
    text-decoration: none;
    color: var(--primary);
}

.typo-body,
p,
ol,
ul {
    font-family: $family-primary;
    font-weight: $font-medium;
    font-size: var(--font-size-18);
    line-height: calc(var(--font-size-18) * 1.5);

    &:not(:last-of-type) {
        margin-bottom: 1em;
    }
}

ol,
ul {
    margin-left: var(--p-24);

    li {
        padding-left: 2rem;
        padding-bottom: 1rem;
    }
}

.typo-legal {
    font-family: $family-primary;
    font-weight: $font-medium;
    font-size: var(--font-size-12);
    line-height: calc(var(--font-size-12) * 1.5);

}

.c-white {
    color: var(--text-secondary);
}

.underline {
    position: relative;
    padding-bottom: var(--p-60);
    margin-bottom: var(--p-76);

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: max(1.5rem, 0.98vw);
        width: max(13.8rem, 8.98vw);
        background-color: var(--secondary);
    }

    @include respond(small) {
        margin-bottom: var(--p-48);
    }
}

.highlight {
    color: var(--tertiary);
    font-size: var(--font-size-30);
    line-height: calc(var(--font-size-30) * 1.2);
    font-weight: 700;
}