.footer {
    display: grid;
    grid-template-columns: 72.65vw 1fr;

    @include respond(small) {
        grid-template-columns: 1fr;
    }

    &__container {
        background-color: var(--primary);
        padding: var(--p-60) var(--m-horizontal);
        display: grid;
        grid-template-columns: 33.52vw 1fr;
        margin-top: -3vw;

        @include respond(small) {
            grid-template-columns: 1fr;
        }
    }

    &__logo {
        grid-column: 1 / 3;
        margin-bottom: var(--p-48);

        @include respond(small) {
            grid-column: 1 / 2;
        }

        svg {
            width: 21vw;
            height: auto;

            @include respond(small) {
                width: 60%;
            }
        }
    }

    &__contact {
        padding-left: 8.65vw;
        position: relative;

        @include respond(small) {
            padding: 0;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: var(--p-48);
            height: 8.13vw;
            width: 1px;
            background-color: var(--bg-primary);

            @include respond(small) {
                content: none;
            }
        }

        a {
            text-decoration: none;
            color: var(--text-secondary);
            border-bottom: 1px solid var(--secondary);

            &:hover {
                color: var(--secondary);
                border-bottom: 1px solid var(--tertiary);
            }
        }
    }

    &__address {
        a {
            text-decoration: none;
            color: var(--text-secondary);
            border-bottom: 1px solid var(--secondary);

            &:hover {
                color: var(--secondary);
                border-bottom: 1px solid var(--tertiary);
            }
        }
    }

    &__partners {
        padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--p-60);

        @include respond(small) {
            padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
        }

        img {
            margin-top: var(--p-48);
            width: 11.71vw;
            height: auto;

            @include respond(small) {
                width: 60%;
            }
        }
    }
}