/* 3rd party packages */
@import "../../node_modules/normalize.css/normalize.css";

/* Functions */
@import "functions/breakpoints";

/* Animations */
@import "animations/animations";

/* Base styles */
@import "base/font";
@import "base/variables";
@import "base/base";
@import "base/typography";


/*Custom styles*/
@import "layout/header";
@import "layout/promo";
@import "layout/projects";
@import "layout/info";
@import "layout/footer";
@import "layout/text";

@import "components/form";