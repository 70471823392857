.promo {
    display: grid;
    grid-template-columns: 48vw 1fr;

    @include respond(small) {
        grid-template-columns: 1fr;
    }

    &__apla {
        position: relative;
        padding: 0 var(--p-60) 3.5vw var(--m-horizontal);
        display: flex;
        align-items: flex-end;

        @include respond(small) {
            padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: calc(100% + 10vw);
            bottom: 0;
            left: 0;
            background-color: var(--tertiary);
            z-index: 2;

            @include respond(small) {
                height: calc(100% + 7rem);
                width: calc(100% - 3rem);
            }
        }

        h2 {
            position: relative;
            color: var(--text-secondary);
            z-index: 3;

        }
    }

    &__text {
        padding: var(--p-76) var(--m-horizontal) var(--p-60) var(--p-60);

        @include respond(small) {
            padding: var(--p-60) var(--m-horizontal) var(--p-60) var(--m-horizontal);

        }
    }
}